import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


import { library } from '@fortawesome/fontawesome-svg-core';
import { faClose, faChevronCircleRight, faChevronDown, faChevronLeft, faChevronRight, faClock, faSortNumericAsc, faGear, faExchange } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faClose, faChevronCircleRight, faChevronDown, faChevronLeft, faChevronRight, faClock, faSortNumericAsc, faGear, faExchange);

import './assets/css/pintv_main.css';
// import './assets/css/pintv_pub.css';
import './assets/css/pure-min.css';

const app = createApp(App);

app.use(store);
app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
