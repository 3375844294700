<script>
export default {
  data() {
    return {
      sports: [
        {
          title: "Wrestling",
          version: "1.1.0",
          cats: [
            {
              path: 0, // path is chosen in this step
              catId: "style",
              q: "Which style of wrestling?",
              data: [
                { value: "Folkstyle", display: "Folkstyle", path: 1 },
                { value: "Freestyle", display: "Freestyle", path: 2 },
                { value: "Greco", display: "Greco", path: 2 },
              ],
            },
            {
              path: 1,
              catId: "age",
              q: "High School or College?",
              data: [
                { value: "Highschool", display: "Highschool" },
                { value: "College", display: "College" },
              ],
            },
            {
              path: 1,
              catId: "team",
              q: "Individual or Team?",
              data: [
                { value: "Individual", display: "Individual" },
                { value: "Team", display: "Team" },
              ],
            },
            {
              path: 1,
              catId: "period",
              q: "How long are the periods today?",
              data: [
                { value: "2:00", display: "2:00 Periods" },
                { value: "1:30", display: "1:30 Periods" },
                { value: "1:00", display: "1:00 Periods" },
              ],
            },
            {
              path: 2,
              catId: "periodAlt",
              q: "How long are the periods today?",
              data: [
                { value: "3:00", display: "3:00 Periods" },
                { value: "2:00", display: "2:00 Periods" },
                { value: "1:30", display: "1:30 Periods" },
              ],
            },
          ],
          script: "wrestling.php",
          enabled: true,
        },
        {
          title: "Basketball",
          version: "beta",
          cats: [
            {
              catId: "numPeriod",
              q: "How many periods are we playing?",
              data: [
                { value: 2, display: "2 Periods" },
                { value: 4, display: "4 Periods" },
              ],
            },
            {
              catId: "periodLength",
              q: "How long are the periods?",
              data: [
                { value: "10:00", display: "10:00 Periods" },
                { value: "15:00", display: "15:00 Periods" },
                { value: "20:00", display: "20:00 Periods" },
              ],
            },
            {
              catId: "timeout",
              q: "How many timeouts does each team get?",
              data: [
                { value: 4, display: "(2) 30 second T.Os<br />(per half)" },
                {
                  value: 5,
                  display: "(3) 60 second &<br /> (2) 30 second T.Os",
                },
                { value: 7, display: "(7) 75 second T.Os" },
              ],
            },
            {
              catId: "shotClock",
              q: "How long is the Shot Clock?",
              data: [
                { value: 24, display: "24s Shot Clock" },
                { value: 30, display: "30s Shot Clock" },
                { value: 0, display: "No Shot Clock" },
              ],
            },
            {
              catId: "overtime",
              q: "How long are the overtime periods?",
              data: [
                { value: "4:00", display: "4:00 Overtime" },
                { value: "5:00", display: "5:00 Overtime" },
              ],
            },
          ],
          script: "basketball.php",
          enabled: true,
        },
        {
          title: "Volleyball",
          version: "beta",
          cats: [
            {
              path: 0,
              catId: "numSets",
              q: "How many sets does it take to win the match?",
              data: [
                { value: 3, display: "2 out of 3" },
                { value: 5, display: "3 out of 5" },
              ],
            },
            {
              path: 0,
              catId: "pointsPerSet",
              q: "How many points does it take to win a set?",
              data: [
                { value: 21, display: "21 points", path: 1 },
                { value: 25, display: "25 points", path: 2 },
              ],
            },
            {
              path: 1,
              catId: "finalSet",
              q: "How many points does it take to win the final set?",
              data: [
                { value: 15, display: "15 points (final set)" },
                { value: 21, display: "21 points (final set)" },
              ],
            },
            {
              path: 2,
              catId: "finalSet",
              q: "How many points does it take to win the final set?",
              data: [
                { value: 15, display: "15 points (final set)" },
                { value: 25, display: "25 points (final set)" },
              ],
            },
            {
              path: 1,
              catId: "pointLimit",
              q: "What are the point limits in each set?",
              data: [
                { value: 23, display: "Win by 2 (Max 23)" },
                { value: -1, display: "Win by 2 (no limit)" },
              ],
            },
            {
              path: 2,
              catId: "pointLimit",
              q: "What are the point limits in each set?",
              data: [
                { value: 27, display: "Win by 2 (Max 27)" },
                { value: -1, display: "Win by 2 (no limit)" },
              ],
            },
            {
              path: 1,
              catId: "timeouts",
              q: "How long are the timeouts?",
              data: [
                { value: 30, display: "30 seconds" },
                { value: 60, display: "60 seconds" },
              ],
            },
            {
              path: 2,
              catId: "timeouts",
              q: "How long are the timeouts?",
              data: [
                { value: 30, display: "30 seconds" },
                { value: 60, display: "60 seconds" },
              ],
            },
          ],
          script: "volleyball.php",
          enabled: true,
        },
      ],
      exceptions: [
        {
          sport: "Wrestling",
          conditions: ["College"], // subcat data values
          catId: "period",
          q: "How long are the periods today?",
          display: "3:00, 2:00, 2:00 Periods",
          value: "x",
        },
      ],
    };
  },
  methods: {
    //see if the exception exists
    checkForException(sport, chosenSubcats, catId) {
      let idx = this.exceptions.findIndex(function (el) {
        let foundConditions = true;
        for (let i = 0; i < el.conditions.length; i++) {
          // if (chosenSubcats.indexOf(el.conditions[i]) == -1)
          //     foundConditions = false;

          let condIdx = chosenSubcats.findIndex(function (item) {
            return item.value == el.conditions[i];
          });
          if (condIdx == -1) foundConditions = false;
        }
        return (
          el.sport == sport && el.catId == catId && foundConditions == true
        );
      });
      return idx > -1;
    },
    //get the exception value
    getExceptionData(sport, catId) {
      let idx = this.exceptions.findIndex(function (el) {
        return el.sport == sport && el.catId == catId;
      });
      return {
        value: this.exceptions[idx].value,
        display: this.exceptions[idx].display,
      };
    },
  },
};
</script>