<template>
  <section id="content">
    <div id="splash_container" style="visibility: hidden">
      <div :style="splashStyle">
        <p>&nbsp;</p>

        <div class="sb-home-box" style="">
          <header>
            <h2>Virtual Scoreboard</h2>
          </header>

          <div id="chooseSport" v-if="chosenSportIdx == null">
            <h4>Which sport are we scoring today?</h4>
            <span v-for="(s, sindex) in sports" :key="sindex">
              <span v-if="s.cats.length > 0 && s.enabled">
                <button
                  @click="setSport(sindex)"
                  class="pure-button button-large"
                >
                  {{ s.title }} ({{ s.version }})
                </button>
              </span>
              <span v-else>
                <button class="pure-button button-large" disabled>
                  {{ s.title }}
                </button>
              </span>
              &nbsp;
            </span>
          </div>

          <div id="showChoicesSoFar" v-if="chosenSportIdx != null">
            Sport: {{ chosenSport }}
            <div
              v-for="(s, sindex) in subChoices"
              :key="sindex"
              style="margin-top: 2vh"
              v-html="s.display"
            ></div>
          </div>

          <div
            id="chooseSubcat"
            v-if="currentSubcat != null"
            style="margin-top: 2vh"
          >
            <div
              v-if="
                checkForException(chosenSport, subChoices, currentSubcat.catId)
              "
            >
              <h4>{{ currentSubcat.q }}</h4>
              <button
                @click="
                  setSubcat(getExceptionData(chosenSport, currentSubcat.catId))
                "
                class="pure-button button-large"
              >
                {{ getExceptionData(chosenSport, currentSubcat.catId).display }}
              </button>
            </div>

            <div v-else>
              <h4>{{ currentSubcat.q }}</h4>
              <span v-for="(s, sindex) in currentSubcat.data" :key="sindex">
                <button
                  @click="setSubcat(s)"
                  class="pure-button button-large"
                  v-html="s.display"
                ></button
                >&nbsp;
              </span>
            </div>
          </div>

          <div v-if="answeredAll == true" style="margin-top: 4vh">
            <h3>Does this look right?</h3>
            <button @click="saveAndNext" style="pure-button">Yes</button>
            <button @click="reset" style="pure-button">No</button>
          </div>
          <div v-else>
            <div v-if="canReset" style="margin-top: 4vh">
              <button @click="reset" style="pure-button">RESET</button>
            </div>
          </div>

          <p>&nbsp;</p>
        </div>
      </div>
      <div v-if="isTrial" class="link-container">
        <a class="esdikay-link" href="https://www.fairlight.link" target="_blank"
          >developer homepage</a
        >
      </div>
    </div>

    <div id="preloader" class="vcenter hcenter">
      <h1>Scoreboard LOADING ...</h1>
    </div>
  </section>
</template>

<script>
import SportsCats from "../mixins/SportsCats.vue";

export default {
  mixins: [SportsCats],
  data() {
    return {
      chosenSport: "",
      chosenSportIdx: null,
      subCatData: [],
      subCatIdx: null,
      subCatPath: 0,
      subChoices: [],
      answeredAll: false,
      //
      isTrial: null,
    };
  },
  created() {
    this.isTrial = this.$store.getters.isTrial;
  },
  mounted() {
    window.localStorage.removeItem("initSport");

    document.getElementById("splash_container").style.visibility = "visible";
    document.getElementById("preloader").style.display = "none";
  },
  computed: {
    canReset() {
      return this.chosenSportIdx !== null;
    },
    currentSubcat() {
      if (this.chosenSportIdx == null) return null;
      if (this.subCatData.length > 0) {
        return this.subCatData[this.subCatIdx];
      }
      return null;
    },
    subCatDepth() {
      return this.subCatData.length;
    },
    splashUrl() {
      return require("../assets/img/splash_referee2.jpg");
    },
    splashStyle() {
      return `width: 100vw; height: 95vh;
            background-image: url("${this.splashUrl}");
            background-size: cover;`;
    },
  },
  methods: {
    setSport(sindex) {
      this.chosenSport = this.sports[sindex].title;
      this.chosenSportIdx = sindex;
      this.subCatData = this.sports[sindex].cats;
      this.subCatIdx = 0;
    },
    setSubcat(obj) {
      document.activeElement.blur(); // focus carries on into next subcat otherwise(!)
      this.subChoices.push(obj);

      // we have a decision path within a sport!
      if (obj.path != undefined) {
        this.subCatPath = obj.path; // reset the sub
        let path = this.subCatPath;
        this.subCatData = this.subCatData.filter(function (el) {
          return el.path == path || el.path == 0;
        });
      }

      this.subCatIdx++;
      if (this.subCatIdx >= this.subCatDepth) this.answeredAll = true;
    },
    saveAndNext() {
      window.localStorage.setItem("initSport", JSON.stringify(this.subChoices));

      // use Vuex store
      this.$store.dispatch("setInitChoices", this.subChoices);
      let replacePath = "/";
      if (this.chosenSport.toLowerCase() === "wrestling") {
        replacePath = "/wrestling";
      } else if (this.chosenSport.toLowerCase() === "basketball") {
        replacePath = "/basketball";
      } else if (this.chosenSport.toLowerCase() === "volleyball") {
        replacePath = "/volleyball";
      }
      this.$router.replace(replacePath);
    },
    reset() {
      this.chosenSport = "";
      this.chosenSportIdx = null;
      this.subCatData = [];
      this.subCatIdx = null;
      this.subChoices = [];
      this.answeredAll = false;
    },
  },
};
</script>

<style scoped>
.link-container {
  text-align: right;
}
.esdikay-link {
  color: black;
  text-decoration: none;
}
.esdikay-link:hover {
  color: blue;
}
</style>