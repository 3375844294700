import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../pages/HomePage.vue';


const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/wrestling',
    name: 'wrestlingPage',
    component: () => import('../pages/WrestlingPage.vue')
  },
  {
    path: '/pub/wrestling',
    name: 'pubWrestling',
    component: () => import('../pages/WrestlingPub.vue'),
  },
  {
    path: '/sum/wrestling',
    name: 'sumWrestling',
    component: () => import('../pages/WrestlingSum.vue'),
  },
  {
    path: '/volleyball',
    name: 'volleyballPage',
    component: () => import('../pages/VolleyballPage.vue')
  },
  {
    path: '/pub/volleyball',
    name: 'pubVolleyball',
    component: () => import('../pages/VolleyballPub.vue'),
  },
  {
    path: '/sum/volleyball',
    name: 'sumVolleyball',
    component: () => import('../pages/VolleyballSum.vue'),
  },
  {
    path: '/basketball',
    name: 'basketballPage',
    component: () => import('../pages/BasketballPage.vue')
  },
  {
    path: '/pub/basketball',
    name: 'pubBasketball',
    component: () => import('../pages/BasketballPub.vue'),
  },
  {
    path: '/sum/basketball',
    name: 'sumBasketball',
    component: () => import('../pages/BasketballSum.vue'),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;