<template>
  <section>
    <router-view/>
  </section>
</template>

<script>
const defaultSettings = {
  isTrial: false,
  isDiag: false,
  htmlTitle: 'Esdikay Scoreboard',
};

export default {
  created() {
    let isTrial = defaultSettings.isTrial;
    if (process.env.VUE_APP_IS_TRIAL && process.env.VUE_APP_IS_TRIAL === "1")
      isTrial = true;
    this.$store.dispatch('setIsTrial', isTrial); 

    let isDiag = defaultSettings.isDiag;
    if (process.env.VUE_APP_IS_DIAG && process.env.VUE_APP_IS_DIAG === "1")
      isDiag = true;
    this.$store.dispatch('setIsDiag', isDiag); 

    let htmlTitle = defaultSettings.htmlTitle;
    if (process.env.VUE_APP_HTML_TITLE && process.env.VUE_APP_HTML_TITLE !== "")
      htmlTitle = process.env.VUE_APP_HTML_TITLE;
    this.$store.dispatch('setHtmlTitle', htmlTitle); 

    document.title = htmlTitle;
  },
};
</script>

<style>
</style>