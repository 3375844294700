import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      initChoices: null,
      isTrial: null,
      isDiag: null,
      htmlTitle: '',
    };
  },
  getters: {
    initChoices(state) {
      return state.initChoices;
    },
    isTrial(state) {
      return state.isTrial;
    },
    isDiag(state) {
      return state.isDiag;
    },
    getHtmlTitle(state) {
      return state.htmlTitle;
    },
  },
  actions: {
    setInitChoices(context, choices) {
      context.commit('setInitChoices', choices);
    },
    setIsTrial(context, isTrial) {
      context.commit('setIsTrial', isTrial);
    },
    setIsDiag(context, isDiag) {
      context.commit('setIsDiag', isDiag);
    },
    setHtmlTitle(context, htmlTitle) {
      context.commit('setHtmlTitle', htmlTitle);
    },
  },
  mutations: {
    setInitChoices(state, choices) {
      state.initChoices = choices;
    },
    setIsTrial(state, isTrial) {
      state.isTrial = isTrial;
    },
    setIsDiag(state, isDiag) {
      state.isDiag = isDiag;
    },
    setHtmlTitle(state, htmlTitle) {
      state.htmlTitle = htmlTitle;
    },
  },
  modules: {
  }
})
